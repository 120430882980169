import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { IdleTimerProvider } from 'react-idle-timer';
import { MapContext } from '../../routes/MapContext';
import { AuthContext } from '../../routes/AuthContext';
import { getMapThemesData } from '../../redux/actions/headerActions';
import { setReportsData, setReportsUpdateStatus, setEmbedToken, setFetchReportsDataStatus } from '../../redux/actions/reportsActions';
import { defaultZoom, translatedErrorMessages, featureLabels, reportTypes, baseApi, AUTH_TYPES } from '../../constant/Constants';
import { configTimers } from '../../constant/Timers';
import { EXCLUDE_MAP, ROUTE_ENDPOINTS } from "../../constant/RouteConstants";
import { getOktaSessionStatus, refreshOktaSession, refreshOktaToken, handleOktaExpiredSession } from '../login/okta/OktaLoginUtils';
import { configAuthType, getApiKey, getGroupProfile, getUserLocaleCode, getOktaAccessToken } from '../../utilities/Utils';
import { isFeatureAllowed } from '../../utilities/CommonUtils';
import { STORAGE_KEYS } from "../../constant/SessionConstants";
import SideMenu from "./SideMenu";
import { toast } from "react-toastify";
import MapView from '../views/MapView';
import { getInsightReports, getEmbedToken } from '../../services/InsightService';
import { useTranslation } from 'react-i18next';
import  NetworkContext from 'sa-common/context/NetworkContext';
import {otherUrls} from '../../urls'

const toastId = "toast-id";
const customToastId = "custom-toast-id";
const Dashboard = (props) => {
  const { t, i18n } = useTranslation()
  const { isOnline, updatePingToken, setPingUrl } = useContext(NetworkContext);
  const location = useLocation();
  // Extract the pathname from the location object
  const currentPath = location.pathname;
  // Generate a className based on the current route
  const className = `content-section ${currentPath.substring(1)}`;
  // const excludeMap = EXCLUDE_MAP.includes(currentPath);
  const { updateMapValues } = useContext(MapContext);
  const { authClient, updateAuthClient } = useContext(AuthContext);
  const [mapStyle, setMapStyle] = useState('');
  const [currentPage, setCurrentPage] = useState(currentPath);
  const [excludeMap, setExcludeMap] = useState(false);
  const [sidebarClass, setSidebarCls] = useState('sidebar-section-open');
  const authType = configAuthType();
  let refreshSessionTimeoutId = null;
  let oktaTokenTimeoutID = {
    accessToken: null,
    idToken: null,
  }
  const renewOktaTokenRetry = {
    accessToken: { count: 0, timeoutID: null },
    idToken: { count: 0, timeoutID: null },
  };
  let isPageActive = true;
  const groupProfile = getGroupProfile();


  useEffect(() => {
    // i18n.changeLanguage("fr-CA")
    i18n.changeLanguage(getUserLocaleCode())
  }, []);

  // useEffect for styles fetching
  useEffect(() => {
    var hideMap = EXCLUDE_MAP.includes(currentPath)
    console.log(":::: hideMap ::: ", hideMap)
    setExcludeMap(hideMap)
    if (!hideMap) {
      fetchMapStyles();
    }
    document.body.className = 'incident-bg';
    
    getReportsData();
  }, []);

  useEffect(() => {
    if (configAuthType() === AUTH_TYPES.OKTA) {
      handleOktaSession();
      handleOktaToken(STORAGE_KEYS.ACCESS_TOKEN);
      handleOktaToken(STORAGE_KEYS.ID_TOKEN);
      showUserUpdateErrMsg();
      setTimeout(() => {
        setPingUrl(`${baseApi.url}/sman/status?api_key=${getApiKey()}`)
        // updatePingToken(getOktaAccessToken())
      }, 1000)
    }
  }, []);

  useEffect(() => {
    if (props.mapUrl) {
      setMapStyle(`${props.mapUrl}?api_key=${getApiKey()}`)
      var hideMap = EXCLUDE_MAP.includes(currentPath)
      console.log(":::: hideMap mapUrl ::: ", hideMap)
      setExcludeMap(hideMap)
    }
  }, [props.mapUrl])

  useEffect(() => {
    if (currentPath == ROUTE_ENDPOINTS.MAP) {
      setSidebarCls('sidebar-section-open right-auto');
    } else {
      setSidebarCls('sidebar-section-open');
    }
  }, [currentPath])
  
  useEffect(() => {
    if (props.fetchReportsDataStatus === true) {
      getReportsData();
    }
  }, [props.fetchReportsDataStatus])

  useEffect(() => {
    if (isOnline) handleOfflineToOnline();
  }, [isOnline]);

  const fetchMapStyles = () => {
    props.getMapThemesData();
  }

  let sessionExpiryCount = 0;
  let sessionExpiryTimeoutId = null;
  const handleOktaSession = () => {
    console.log('Getting session: ');
    authClient.session.refresh().then((session) => {
      console.log("::::::::::::: session refresh ::::: " + session.id);
      sessionExpiryCount = 0;
      let currentDateTime = new Date().getTime();
      let sessionExpireTime = (new Date(session.expiresAt)).getTime();
      /* Refresh session - 60 sec. before to actual session expiry time */ 
      let nextTimeout = (sessionExpireTime - currentDateTime) - 60000;
      setTimeout(() => {
        handleOktaSession();
      }, nextTimeout > 0 ? nextTimeout : configTimers.refreshTokenExpiryTime);
    }).catch((exception) => {
      const exceptionMsg = (exception.message) ? exception.message : exception;
      console.log('setSessionExpiry Exception::::::: ' + exceptionMsg);
      if (sessionExpiryTimeoutId) clearTimeout(sessionExpiryTimeoutId);
      sessionExpiryTimeoutId = setTimeout(() => {
        sessionExpiryCount += 1;
        if (sessionExpiryCount > 3) {
          console.log('logout');
          sessionExpiryCount = 0;
          handleOktaExpiredSession();
        } else {
          console.log('sessionExpiry Failure Count:', sessionExpiryCount);
          handleOktaSession();
        }
      }, configTimers.sessionTokenRetry);
    })
  }

  const handleOktaToken = (tokenType) => {
    getOktaSessionStatus(authClient)
      .then((session) => {
        console.log('session status:', session.status);
        if (session && session.status && session.status === 'ACTIVE') {
          const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
          const tokenInfo = oktaTokens[tokenType];
          if (tokenInfo) {
            let currentTime = new Date().getTime();
            let nextTimeout = (tokenInfo.expiresAt * 1000) - currentTime;
            console.log(`${tokenType} next timeout:`, nextTimeout);
            if (nextTimeout > 0) {
              let tokenTimeoutID = oktaTokenTimeoutID[tokenType];
              if (tokenTimeoutID) clearTimeout(tokenTimeoutID);
              const timeOut = (nextTimeout > 60000) ? (nextTimeout - 60000) : nextTimeout;
              oktaTokenTimeoutID[tokenType] = setTimeout(() => {
                handleOktaTokenRefresh(authClient, tokenType);
              }, timeOut);
            } else {
              console.log(`::::::::: ${tokenType} Expired :::::::::::::: `);
              handleOktaTokenRefresh(authClient, tokenType);
            }
          }
        } else {
          handleOktaExpiredSession();
        }
      })
      .catch((err) => {
        console.log(`::::::::: handleOktaToken Exception :::::::::::::: `, err);
      });
  }


  const handleOktaTokenRefresh = (authClient, tokenType) => {
    refreshOktaToken(authClient, tokenType)
      .then((renewedToken) => {
        console.log(`::::::::::::: New ${tokenType} generated ::::::::::: `);
        renewOktaTokenRetry[tokenType]['count'] = 0;
        authClient.tokenManager.add(tokenType, renewedToken);
        authClient.authStateManager.updateAuthState();
        handleOktaToken(tokenType);
        if (tokenType === STORAGE_KEYS.ACCESS_TOKEN) {
          updatePingToken(renewedToken.accessToken)
        }
      })
      .catch(exception => {
        const errorMessage = (exception.message) ? exception.message : exception;
        if (exception.message) console.log(`renew ${tokenType} exception message:`, errorMessage);
        console.log(`::::: Renew ${tokenType} failure count :::::`, renewOktaTokenRetry[tokenType]['count']);
        if (renewOktaTokenRetry[tokenType]['count'] <= 3) {
          if (renewOktaTokenRetry[tokenType]['timeoutID']) clearTimeout(renewOktaTokenRetry[tokenType]['timeoutID']);
          renewOktaTokenRetry[tokenType]['timeoutID'] = setTimeout(() => {
            renewOktaTokenRetry[tokenType]['count'] += 1;
            handleOktaTokenRefresh(authClient, tokenType);
          }, 30000);
        } else {
          console.log(`::::::::: handleOktaTokenRefresh Exception :::::::::::::: `);
          handleOktaExpiredSession('tokens');
        }
      })
  }

  // Function to set mapContainer and mapRef when the map component is loaded
  const handleMapLoad = (container, ref) => {
    updateMapValues(container, ref);
  };
  const handleOnAction = (event) => {
    if (event.type === 'visibilitychange') {
      if (event.target.visibilityState === 'hidden') {
        isPageActive = false;
      } else {
        isPageActive = true;
        if (window.navigator.onLine && authType === AUTH_TYPES.OKTA) setTimeout(() => checkSessionValidity(), 5000);
      }
    }
  }
  const checkSessionValidity = () => {
    if (sessionStorage.getItem('currentUser') !== null) {
      getOktaSessionStatus(authClient)
        .then((session) => {
          console.log('check session status:', session.status);
          if (session.status === 'INACTIVE') {
            handleOktaExpiredSession();
          }
        })
        .catch((err) => {
          console.log(`::::::::: checkSessionValidity Err :::::::::::::: `, err);
        });
    }
  }

  const showUserUpdateErrMsg = () => {
    if (sessionStorage.getItem('showUserUpdateError') == 'true') {
      setTimeout(() => {
        toast.warning(translatedErrorMessages.userUpdateFailed(), { toastId: toastId });
        sessionStorage.removeItem('showUserUpdateError');
      }, 2000)
    }
  }

  const convertToRequireFormat = (originalResponse) => {
    if (originalResponse) {
      // Convert to the desired format
      const convertedResponse = {
        "embedtoken": originalResponse.embedtoken,
        "expires_at": originalResponse.expires_at,
        "reports": []
      };
      originalResponse.workspaces.forEach(workspace => {
        if (workspace && workspace.datasets) {
          workspace.datasets.forEach(dataset => {
            if (dataset && dataset.reports) {
              dataset.reports.forEach(report => {
                if (report && report.pages) {
                  convertedResponse.reports.push({
                    report_id: report.report_id,
                    datasetid: dataset.datasetid ,
                    workspaceid: workspace.workspaceid ,
                    powerbi_reportid: report.powerbi_reportid ,
                    report_name: report.report_name ,
                    pages: report.pages || [],
                    embed_url: report.embed_url ,
                    report_type: report.report_type ,
                    created_by: report.created_by || null,
                    created_at: report.created_at ,
                    updated_at: report.updated_at 
                  });
                }
              });
            }
          });
        }
      });

      // Sort reports based on report_type order
      convertedResponse.reports.sort((a, b) => {
        const typeAIndex = Object.values(reportTypes).indexOf(a.report_type);
        const typeBIndex = Object.values(reportTypes).indexOf(b.report_type);
        return typeAIndex - typeBIndex;
      });
      return convertedResponse;
    } else {
      return {
        "embedtoken": "",
        "expires_at": "",
        "reports": []
      };
    }
  }

  const getReportsData = () => {
    getInsightReports().then(response => { 
      if (response && response.status === 200) {
        const convertedResponse = convertToRequireFormat(response.data)
        if (convertedResponse && convertedResponse.reports) {
          props.setEmbedToken(convertedResponse.embedtoken);
          checkEmbedTokenExpiry(convertedResponse.expires_at);
          props.updateReportsData(convertedResponse.reports);
          props.setReportsUpdateStatus(true);
          props.setFetchReportsDataStatus(false);
        } else {
          props.updateReportsData([]);
          props.setReportsUpdateStatus(true);
          props.setFetchReportsDataStatus(false);
        }
      }
    })
    .catch(error => {
      props.setEmbedToken(null);
      props.updateReportsData([]);
      props.setReportsUpdateStatus(true);
      props.setFetchReportsDataStatus(false);
      console.log('error: ', error);
    });
  }

  let embedTokenTimeoutID = null;
  const checkEmbedTokenExpiry = (tokenExpiration) => {
    const currentTime = Date.now();
    const expiration = Date.parse(tokenExpiration);
    const advTime = 2 * 60 * 1000; //2mins before
    let nextTimeout = expiration - currentTime;
    if (nextTimeout > 0) {
      if (embedTokenTimeoutID) clearTimeout(embedTokenTimeoutID);
      nextTimeout = (nextTimeout > advTime) ? (nextTimeout - advTime) : nextTimeout;
      embedTokenTimeoutID = setTimeout(() => {
        refreshEmbedToken();
      }, nextTimeout);
    } else {
      refreshEmbedToken();
    }
  }

  let embedTokenRetryCount = 0;
  let embedTokenRetryTimeout = null;
  const refreshEmbedToken = async () => {
    console.log('--- Refresh embed token ---');
    getEmbedToken().then(response => {      
      if (response && response.status === 200 && response.data) {
        props.setEmbedToken(response.data.embedtoken);
        checkEmbedTokenExpiry(response.data.expires_at);
        embedTokenRetryCount = 0;
      }
    })
    .catch(exception => {
      console.log('error: ', exception);
      if (embedTokenRetryCount < 3) {
        if (embedTokenRetryTimeout) clearTimeout(embedTokenRetryTimeout);
        embedTokenRetryTimeout = setTimeout(() => {
          embedTokenRetryCount += 1;
          refreshEmbedToken();
        }, 15000);
      } else {
        console.log(`::::::::: Refresh embed token falied!! ::::::::: `);
      }
    });
  }

  const handleOfflineToOnline = () => {
    if(isOnline) {
      getReportsData();
    }
  }

  return (
    <div>
      <div className="d-flex">
        <div className="left-side">
          <SideMenu />
        </div>
        <div className="right-section">
          <div className={sidebarClass}>
            <Outlet />
          </div>
          {!excludeMap && isFeatureAllowed(featureLabels.map) ? <div className={className}>
            <div className="map-container">
              {mapStyle ? (
                <MapView
                  mapStyle={mapStyle}
                  zoom={groupProfile.map.zoom || defaultZoom}
                  center={groupProfile.map.center}
                  handleMapLoad={handleMapLoad}
                />
              ) : (<div>Loading Map...</div>)}
            </div>
          </div>
            : <div></div>}
        </div>
        <IdleTimerProvider timeout={1000 * 60 * 5} onAction={handleOnAction} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
    mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
    fetchReportsDataStatus: state.reports.fetchReportsDataStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMapThemesData: () => dispatch(getMapThemesData()),
    updateReportsData: (data) => dispatch(setReportsData(data)),
    setReportsUpdateStatus: (status) => dispatch(setReportsUpdateStatus(status)),
    setEmbedToken: (token) => dispatch(setEmbedToken(token)),
    setFetchReportsDataStatus: (status) => dispatch(setFetchReportsDataStatus(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);