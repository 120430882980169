import { STORAGE_KEYS } from "../constant/SessionConstants";
import { authConfig, appCode, locale, languageStr, reportAccessRoles, AUTH_TYPES } from "../constant/Constants";

export const configAuthType = () => {
  return authConfig && authConfig.authType ? authConfig.authType : AUTH_TYPES.OKTA;
}

export function getLoginToken() {
  return sessionStorage.getItem(STORAGE_KEYS.ID_TOKEN) || getOktaIdTokenFromSession();
}

export function getApiKey() {
  return sessionStorage.getItem(STORAGE_KEYS.API_KEY);
}

export function getBaseUrl() {
  return sessionStorage.getItem(STORAGE_KEYS.BASE_URL);
}

export function getUserName() {
  return sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
}

export function getGroupProfile() {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE));
}

export function getAgency() {
  var userInfo = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_INFO));
  if (userInfo && userInfo.agency && userInfo.agency.trim() !== '') {
    return userInfo.agency;
  }
  return null;
}
export function getUserLanguage() {
  const currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
  let usersettingsInfo = JSON.parse(localStorage.getItem(STORAGE_KEYS.USERSETTINGS));
  if (usersettingsInfo && currentUser) {
    const settingsData = usersettingsInfo.find(data => data.userName === currentUser);
    if (settingsData) {
      return settingsData.language;
    }else{
    return languageStr.english;
    }
  }
  return languageStr.english;
}

export function getUserDate() {
  const currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
  let usersettingsInfo = JSON.parse(localStorage.getItem(STORAGE_KEYS.USERSETTINGS));
  if (usersettingsInfo && currentUser) {
    const settingsData = usersettingsInfo.find(data => data.userName === currentUser);
    if (settingsData) {
      return settingsData.dateFormat;
    } else {
      return languageStr.english;
    }
  }
  return languageStr.english;
}

export function getUserLocaleCode() {
  const preferedLang = getUserLanguage()
  // Define a mapping from language strings to locales
  const languageToLocale = {
    [languageStr.english]: locale.us,
    [languageStr.french]: locale.fr
  };
  // Return the corresponding locale if a preferred language is found, otherwise default to 'us'
  return preferedLang && preferedLang in languageToLocale ? languageToLocale[preferedLang] : locale.us;
}

export function getUserDateFormatCode() {
  const userDateFormat = getUserDate();
  const languageToLocale = {
    [languageStr.english]: locale.us,
    [languageStr.french]: locale.fr
  };
  // Return the corresponding locale if a preferred language is found, otherwise default to 'us'
  return userDateFormat && userDateFormat in languageToLocale ? languageToLocale[userDateFormat] : locale.us;
}

export const getOktaIdTokenFromSession = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  if (currentUser && currentUser != null) {
    const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    return (oktaTokens && oktaTokens.idToken) ? oktaTokens.idToken.idToken : null;
  } else {
    return sessionStorage.getItem('idToken') || null;
  }
}

export const getOktaAccessTokenFromSession = () => {
  const currentUser = sessionStorage.getItem("currentUser");
  if (currentUser && currentUser != null) {
    const oktaTokens = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    return (oktaTokens && oktaTokens.accessToken) ? oktaTokens.accessToken.accessToken : null;
  } else {
    return sessionStorage.getItem('accessToken') || null;
  }
}

export const getOktaAccessToken = () => {
  return sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || getOktaAccessTokenFromSession();
}
export const getCurrentUser = () => {
  return sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
}

export const getUserReportAccessRole = () => {
  var userInfo = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_INFO));
  if (userInfo && userInfo.report_access) {
    return userInfo.report_access;
  }
  return reportAccessRoles.LIMITED_VIEWER;
}

export const getFormattedUsername = () => {
  if (getCurrentUser() != null) {
    // Add "sman_" as a prefix:
    const prefixedEmail = appCode + "_" + getCurrentUser();
    // Replace "@" with "_":
    const modifiedEmail = prefixedEmail.replace("@", "_");
    return modifiedEmail;
  }
  return null
}

export const getTenantName = () => {
  return sessionStorage.getItem(STORAGE_KEYS.TENANT_NAME);
}

export const updateUserLoginTime = (curAuthTime) => {
  const currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
  const userLastLoginInfo = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_LAST_LOGIN) || '[]');
  const index = userLastLoginInfo.findIndex(item => item.userName === currentUser);
  if (index !== -1) {
    const lastLoginTime = userLastLoginInfo[index].currentLoginTime;
    userLastLoginInfo[index] = {
      userName: currentUser, 
      currentLoginTime: curAuthTime,
      lastLoginTime: lastLoginTime,
    };
  } else {
    userLastLoginInfo.push({ userName: currentUser, currentLoginTime: curAuthTime, lastLoginTime: curAuthTime });
  }
  localStorage.setItem(STORAGE_KEYS.USER_LAST_LOGIN, JSON.stringify(userLastLoginInfo));
}

export const getUserLastLoginTime = () => {
  const currentUser = sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER);
  const userLastLoginInfo = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_LAST_LOGIN));
  if (userLastLoginInfo && currentUser) {
    const loginData = userLastLoginInfo.find(data => data.userName === currentUser);
    if (loginData) {
      return loginData.lastLoginTime;
    }
  }
  return '';
}