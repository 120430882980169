import { useEffect, useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import Spinner from 'react-bootstrap/Spinner';
import OktaLogin from "../login/okta/OktaLogin";
import { getLoginToken, configAuthType, updateUserLoginTime } from "../../utilities/Utils";
import {handleSaveSettings} from '../../utilities/CommonUtils'
import { baseApi, errorMessages, apiAuthParams, languageStr, appName } from "../../constant/Constants";
import { ROUTE_ENDPOINTS } from "../../constant/RouteConstants";
import { STORAGE_KEYS } from "../../constant/SessionConstants";
import { primaryRegionData, secondaryRegionData, oktaUrls } from '../../oktaexport-client';
import { AuthContext } from '../../routes/AuthContext';
import { getOktaUserAPIKey, updateGroupProfile, updateUserProfile, getUserApplications } from '../../services/OktaServices';
import { oktaSignOut } from "../login/okta/OktaLoginUtils";
import { useTranslation } from 'react-i18next';
import moment from "moment";

import "../../styles/auth.scss";
import saLogo from "../../assets/logo.png";

function OktaLoginPage(props) {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { authClient, updateauthClient } = useContext(AuthContext);
  const [alertMsg, setAlertMsgContent] = useState(null);
  const [showSignInForm, setShowSignInForm] = useState(true);
  const isLoggedIn = getLoginToken();
  let isLoginCallback = false;
  let apiKey = null;
  let userInfo = null;
  let oktaUserId = null;

  useEffect(() => {
    const issParam = props.issParam;
    console.log("iss:: " , issParam);
    isLoginCallback = props.isLoginCallback;
    if (authClient && (issParam || isLoginCallback)) {
      setShowSignInForm(false);
      if (issParam) findExistingSession();
      if (isLoginCallback) handleLoginCallback();
    }
    if (localStorage.getItem('showSignInErrMsg') == 'true') {
      setAlertMsgContent(localStorage.getItem('signInErrMsg'));
      localStorage.removeItem('signInErrMsg');
      localStorage.removeItem('showSignInErrMsg');
    }
  }, [])

  const findExistingSession = () => {
    authClient.token.getWithRedirect({
      responseType: ['token', 'id_token'],
    })
    .catch((err) => {
      // console.log('check session error:', err);
      setShowSignInForm(true);
    });
  }

  const handleLoginCallback = () => {
    authClient.token.parseFromUrl()
    .then((res) => {
      if (res && res.tokens) handleLoginSuccess(res.tokens)
      else setShowSignInForm(true);
    })
    .catch((err) => {
      // console.log('login callback error:', err);
      setShowSignInForm(true);
    });
  }

  const handleLoginSuccess = (tokens) => {
    setTokenAndUserInfo(tokens);
  }

  const handleLoginFailure = () => {
    sessionStorage.clear();
    localStorage.setItem('showSignInErrMsg', true);
    localStorage.setItem('signInErrMsg', t("errorMessages.loginFailedError"));
    window.location.href = "/";
  }

  const setTokenAndUserInfo = async (tokens) => {
    try {
      authClient.tokenManager.add("idToken", tokens.idToken);
      authClient.tokenManager.add("accessToken", tokens.accessToken);
      oktaUserId = tokens.accessToken.claims.uid;

      authClient.token.getUserInfo(tokens.accessToken, tokens.idToken)
      .then(async (userinfo) => {
        // console.log('userinfo:', userinfo);
        
        if (userinfo.user_role.toLowerCase() === 'responder') {
          await denyAccessToApp();
          return;
        }
        
        let userApiKey = userinfo.api_key || null;
        let groupUpdated = userinfo.updated;
        let userDataUpdated = userinfo.user_updated;

        if (!userApiKey) {
          const modifiedGroupNames = modifyGroupNames(userinfo.groups);
          const userGroup = userGroupName(modifiedGroupNames);
          await getAPIKey(userinfo.email, [userGroup]);
          userinfo.api_key = userApiKey = apiKey;
        }

        if (groupUpdated === true) {
          const groupToUpdate = userGroupName(userinfo.groups);
          console.log('groupToUpdate: ', groupToUpdate);
          await updateGroupProfileData(groupToUpdate);
        }

        if (userDataUpdated === true) {
          await updateUserProfileData(userinfo.email, userinfo.api_key);
        }

        if (userApiKey) handleUserInfoResponse(userinfo)
        else handleLoginFailure();
      })
      .catch((err) => {
        console.log('fetch userinfo - failed');
        handleLoginFailure();
      });
    } catch (exception) {
      console.log('::::::::: getWithoutPrompt Exception :::::::::', exception.message);
      if (exception.message && exception.message.includes('User is not assigned to the client application')) {
        handleLoginFailure(t("errorMessages.noAccessToApplication"));
      }
    }
  }

  const handleUserInfoResponse = async (userinfo) => {
    const modifiedGroups = modifyGroupNames(userinfo.groups);
    userinfo.groups = modifiedGroups;
    userInfo = userinfo;
    const groupProfile = {
      name: userinfo.groups[0],
      tenant_name: userinfo.tenant_name,
      agency: userinfo.agency,
      user_role: userinfo.user_role,
      map: {
        center: userinfo.center,
        zoom: userinfo.zoom,
      },
      endCallTimer: userinfo.endCallTimer || 0,
      features: userinfo.features || [],
      licenses: userinfo.licenses,
      updated: userinfo.updated,
    }
    sessionStorage.setItem("groupProfile", JSON.stringify(groupProfile));
    await getUserApps();
    await storeUserInfo();
  }

  const storeUserInfo = () => {
    var currentRegion = localStorage.getItem('currentRegion') || primaryRegionData.aws_project_region;
    const regionData = ((currentRegion != null || currentRegion) && currentRegion === primaryRegionData.aws_project_region) ? primaryRegionData : secondaryRegionData;
    const currentUser = userInfo.email;
    baseApi.url = regionData.baseUrl;
    baseApi.smanUrl = regionData.smanBaseUrl + '/' + apiAuthParams[configAuthType()];
    localStorage.setItem(STORAGE_KEYS.CURRENT_REGION, regionData.aws_project_region);
    sessionStorage.setItem(STORAGE_KEYS.BASE_URL, regionData.baseUrl);
    sessionStorage.setItem(STORAGE_KEYS.SMAN_BASE_URL, baseApi.smanUrl);
    sessionStorage.setItem(STORAGE_KEYS.CURRENT_USER, currentUser);
    sessionStorage.setItem(STORAGE_KEYS.USER, currentUser.split('@')[0]);
    sessionStorage.setItem(STORAGE_KEYS.API_KEY, userInfo.api_key);
    sessionStorage.setItem(STORAGE_KEYS.USER_INFO, JSON.stringify(userInfo));
    sessionStorage.setItem(STORAGE_KEYS.TENANT_NAME, userInfo.tenant_name ? userInfo.tenant_name : '');
    sessionStorage.setItem(STORAGE_KEYS.AGENCY, userInfo.agency ? userInfo.agency : '');

    const userLanguage = (userInfo && userInfo.language && userInfo.language.trim()) || languageStr.english;
    let usersettingsInfo = JSON.parse(localStorage.getItem(STORAGE_KEYS.USERSETTINGS));
    if (usersettingsInfo && currentUser) {
      const settingsData = usersettingsInfo.find(data => data.userName === currentUser);
      if (settingsData) {
        // Do nothing
      } else {
        handleSaveSettings(userLanguage, userLanguage)
      }
    } else {
      handleSaveSettings(userLanguage, userLanguage)
    }
    let groups = userInfo.groups;
    if (groups) {
      const groupName = userGroupName(groups);
      sessionStorage.setItem(STORAGE_KEYS.GROUP_NAME, groupName);
    }
    updateUserLoginTime(moment().unix());
    navigate(ROUTE_ENDPOINTS.INSIGHTS);
  }

  const getAPIKey = async (username, groups) => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaApplicationId = tokenStorage.idToken.clientId;
    await getOktaUserAPIKey(oktaApplicationId, oktaUserId, username, groups, oktaAccessToken)
    .then((response) => {
      console.log('api key response:', response.data);
      if (response && response.data && response.data.key)
        apiKey = response.data.key;
    })
    .catch((error) => {
      console.log('Generate api key - failed');
      handleLoginFailure();
      window.location.href = "/";
    });
  }

  const updateGroupProfileData = async (groupName) => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaApplicationId = tokenStorage.idToken.clientId;
    await updateGroupProfile(oktaApplicationId, groupName, oktaAccessToken)
    .then((response) => {
      if (response.status == 200) console.log('Update group response:', response.data)
      else handleGroupUpdateFailure();
    })
    .catch((error) => {
      console.log(`Update ${groupName} group profile - failed`);
      handleGroupUpdateFailure();
    });
  }

  const updateUserProfileData = async (username, apiKey) => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaApplicationId = tokenStorage.idToken.clientId;
    await updateUserProfile(oktaApplicationId, oktaUserId, username, apiKey, oktaAccessToken)
    .then((response) => {
      if (response.status == 200) console.log('Update user response:', response.data)
      else handleUserUpdateFailure();
    })
    .catch((error) => {
      console.log(`Update ${username} profile - failed`);
      handleUserUpdateFailure();
    });
  }

  const getUserApps = async () => {
    const tokenStorage = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.OKTA_TOKENS));
    const oktaAccessToken = tokenStorage.accessToken.accessToken;
    const oktaAppId = oktaUrls.CLIENT_ID;
    await getUserApplications(oktaUserId, oktaAccessToken)
    .then((response) => {
      if (response.status == 200) {
        if (response.data && response.data.length) {
          let appsData = response.data;
          appsData.map(app => {
            app.isCurrentApp = (app.id === oktaAppId);
            return app;
          });
          sessionStorage.setItem(STORAGE_KEYS.USER_APPLICATIONS, JSON.stringify(appsData));
        } else {
          handleGetUserAppsFailure();
        }
      } else {
        handleGetUserAppsFailure();
      }
    })
    .catch((error) => {
      console.log(`Get apps assigned to user - failed`);
      handleGetUserAppsFailure();
    });
  }

  const handleGetUserAppsFailure = () => {
    const defaultAppData = [
      {
        id: oktaUrls.CLIENT_ID,
        label: oktaUrls.APP_NAME,
        url: '',
        isCurrentApp: true,
      }
    ]
    sessionStorage.setItem(STORAGE_KEYS.USER_APPLICATIONS, JSON.stringify(defaultAppData));
  }

  const modifyGroupNames = (groups) => groups.map(group => group.replace(/SMAN-/i, ''));

  const userGroupName = (groups) => {
    const excludeGroups = [];
    const filterGroups = groups.filter(group => !excludeGroups.includes(group));
    return filterGroups[0];
  }

  const handleGroupUpdateFailure = () => {
    sessionStorage.setItem('showGroupUpdateError', true);
  }

  const handleUserUpdateFailure = () => {
    sessionStorage.setItem('showUserUpdateError', true);
  }

  const denyAccessToApp = async () => {
    await oktaSignOut(authClient);
    localStorage.setItem('signInErrMsg', t("errorMessages.noAccessToApplication"));
    localStorage.setItem('showSignInErrMsg', true);
  }

  const loginComp = () => {
    if (showSignInForm === false) {
      return (
        <div className="loaderDiv">
          <Spinner animation="border" variant="primary">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )
    }

    return (
      <>
        <div className="header">
          <div className="psap-header header-bar">
            <div className="navbar-header container">
              <div className="d-flex align-items-center">
                <img src={saLogo} className='sa-logo' />
                <span className="logo-text">{appName}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="main container">
          <div className="row">
            <div className="col-md-8 col-sm-12 mt-10">
              {alertMsg ? (
                <Alert key="signInAlert" variant="danger">
                  {alertMsg}
                </Alert>
              ) : ''}
            </div>
            <div className="col-md-6 col-sm-12 mt-10">
              { showSignInForm ? (
              <OktaLogin
                onLoginSuccess={handleLoginSuccess}
                onLoginError={handleLoginFailure}
              />) : '' }
            </div>
            <div className="col-md-6 hidden-sm-down"></div>
          </div>
        </div>
      </>
    )
  }

  const homeComp = () => {
    return (<Navigate to={{ pathname: ROUTE_ENDPOINTS.INSIGHTS }} />)
  }

  return (isLoggedIn ? homeComp() : loginComp());
}

export default OktaLoginPage;
