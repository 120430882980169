import {
    FETCH_SPLIT_SCREEN,
    FETCH_MAP_THEME_REQUEST, 
    FETCH_MAP_THEME_SUCCESS, 
    FETCH_MAP_THEME_FAIL, 
    FETCH_MAP_THEME_URL, 
    FETCH_RENDER_THEME,
    FETCH_MAP_THEME_CANCEL,
    EDIT_INSIGHT_REP_STATUS,
    ACTIVE_INSIGHT_REPORT,
    REFRESH_CALLS,
    ACTION_STATUS,
  } from '../actions/actionTypes';

const splitScreen = { isSplitScreen: false };
export const SplitScreenReducer = (state = splitScreen, action) => {
    switch (action.type) {
        case FETCH_SPLIT_SCREEN:
            return {
                ...state,
                isSplitScreen: action.data
            }
        default:
            return state;
    }
}

const initialState = {
    error: '',
    isLoading: false
}
export const MapThemesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MAP_THEME_REQUEST:
            return { ...state, isLoading: action.isLoading }
        case FETCH_MAP_THEME_SUCCESS:
            return { isLoading: action.isLoading, data: action.data, error: '' }
        case FETCH_MAP_THEME_FAIL:
            return { error: action.error, isLoading: false }
        case FETCH_MAP_THEME_CANCEL:
            return { cancelError: action.error, callEnable: action.retires, isLoading: false }
        default:
            return state;
    }
}

const mapUrl = '';
export const MapThemeUrlReducer = (state = mapUrl, action) => {
    switch (action.type) {
        case FETCH_MAP_THEME_URL:
            return {
                ...state,
                mapUrl: action.data,
                styleLoad: true
            }
        default:
            return state;
    }
}

const mapRender = 0;
export const MapRenderReducer = (state = mapRender, action) => {
    switch (action.type) {
        case FETCH_RENDER_THEME:
            return {
                ...state,
                mapRender: action.data
            }
        default:
            return state;
    }
}

const editReport = { isEditReportOn: false };
export const EditReportReducer = (state = editReport, action) => {
    switch (action.type) {
        case EDIT_INSIGHT_REP_STATUS:
            return {
                ...state,
                isEditReportOn: action.data
            }
        default:
            return state;
    }
}

const activeInsightReport = { activeInsightReport: null };
export const ActiveReportReducer = (state = activeInsightReport, action) => {
    switch (action.type) {
        case ACTIVE_INSIGHT_REPORT:
            return {
                ...state,
                activeInsightReport: action.data
            }
        default:
            return state;
    }
}

const refreshApi = { refreshApi: false };
export const RefreshCallsReducer = (state = refreshApi, action) => {
    switch (action.type) {
        case REFRESH_CALLS:
            return {
                ...state,
                refreshApi: action.data
            }
        default:
            return state;
    }
}

const actionStatus = { trafficStatus: false, weatherStatus: false };
export const actionStatusReducer = (state = actionStatus, action) => {
    switch (action.type) {
        case ACTION_STATUS:
            return {
                ...state,
                [action.data.statusType]: action.data.status
            }
        default:
            return state;
    }
}
