export const ROUTE_ENDPOINTS = {
    LOGIN: '/login',
    INSIGHTS: '/insights',
    MAP: '/map',
    PROFILE: '/profile',
    SETTINGS: '/settings',
    ABOUT: '/about'
};

export const EXCLUDE_MAP = [
   ROUTE_ENDPOINTS.INSIGHTS,
   ROUTE_ENDPOINTS.PROFILE,
   ROUTE_ENDPOINTS.SETTINGS,
   ROUTE_ENDPOINTS.ABOUT,
];