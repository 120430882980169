import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FaGlobeAmericas } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { BiSolidDashboard } from "react-icons/bi";
import { MdBarChart, MdSupportAgent, MdEventNote } from "react-icons/md";
import 'sa-common/sidemenu/sidemenu.scss';
import '../../styles/sidemenu.scss';
import { ROUTE_ENDPOINTS } from '../../constant/RouteConstants';
import { reportTypes, featureLabels } from '../../constant/Constants';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { isFeatureAllowed } from '../../utilities/CommonUtils';
import { PiChartBarHorizontalFill } from "react-icons/pi";

function SideMenu(props) {

    const location = useLocation();
    // Extract the pathname from the location object
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [showReportIcons, setShowRepIcons] = useState(false);
    const [activeReportType, setActiveRepType] = useState(null);

    useEffect(() => {
        if (props.reportsData && props.reportsData.length) {
            setShowRepIcons(true);
        } else {
            setShowRepIcons(false);
        }
    }, [props.reportsData])

    useEffect(() => {
        if (props.activeInsightReport && props.activeInsightReport.report_type) {
            const activeRepType = (currentPath == ROUTE_ENDPOINTS.INSIGHTS) ? props.activeInsightReport.report_type : null;
            setActiveRepType(activeRepType);
        }
    }, [props.activeInsightReport])

    useEffect(() => {
        if (currentPath && currentPath !== ROUTE_ENDPOINTS.INSIGHTS) setActiveRepType(null);
    }, [currentPath])

    const activeRouteColor = ({ isActive }) => {
        return { color: isActive ? "#7800F7" : "#666666" };
        // return isActive;
    }

    const loadInsightReport = (reportType) => {
        if (reportType === reportTypes.CUSTOM) {
            navigate(ROUTE_ENDPOINTS.INSIGHTS);
        } else {
            navigate(ROUTE_ENDPOINTS.INSIGHTS, {state: { reportType: reportType }});
        }
    }

    const TooltipWrapper = ({ children, content }) => {
        return (
            <OverlayTrigger placement="right" overlay={(props) => renderTooltip(props, content)} delay={{show: 200, hide: 0}}>
                {children}
            </OverlayTrigger>
        )
    }

    const renderTooltip = (props, content) => (<Tooltip className="menu-tooltip" {...props}>{content}</Tooltip>)

    const getReportIcon = (type, isActive) => {
        switch (type) {
            case reportTypes.DASHBOARD:
                return (
                    <BiSolidDashboard 
                        className={`report-icon icon-border ${isActive ? 'active' : ''}`} 
                    />
                );
            case reportTypes.STANDARD:
            case reportTypes.NGCS:
                return (
                    <MdBarChart 
                        className={`report-icon icon-border ${isActive ? 'active' : ''}`} 
                    />
                );
            case reportTypes.AGENT:
                return (
                    <MdSupportAgent 
                        size="28px" 
                        className={`report-icon ${isActive ? 'active' : ''}`} 
                    />
                );
            case reportTypes.EVENT:
                return (
                    <MdEventNote 
                        size="28px" 
                        className={`report-icon ${isActive ? 'active' : ''}`} 
                    />
                );
            default:
                return (
                    <PiChartBarHorizontalFill 
                         className={`report-icon icon-border ${isActive ? 'active' : ''}`} 
                    />
                );
        }
    };

    const ReportIcon = ({ type, activeReportType }) => {
        const isActive = activeReportType === type;
        return getReportIcon(type, isActive);
    };
    
    return (
        <>
            <div className="sideMenu">
                <div>
                    <TooltipWrapper content="Reports">
                        <NavLink to={ROUTE_ENDPOINTS.INSIGHTS} style={activeRouteColor} className="sideIcon reports">
                            <TbDeviceDesktopAnalytics />
                        </NavLink>
                    </TooltipWrapper>
                </div>
                {isFeatureAllowed(featureLabels.map) ? 
                <div>
                    <TooltipWrapper content="Call Info">
                        <NavLink to={ROUTE_ENDPOINTS.MAP} style={activeRouteColor} className="sideIcon callsInfo">
                            <FaGlobeAmericas />
                        </NavLink>
                    </TooltipWrapper>
                </div>
                : '' }
                <div>
                    <TooltipWrapper content="Settings">
                        <NavLink to={ROUTE_ENDPOINTS.SETTINGS} style={activeRouteColor} className="sideIcon">
                            <BsGearFill />
                        </NavLink>
                    </TooltipWrapper>
                </div>

                <div className="menu-divider" />
                {showReportIcons ? (
                    props.reportsData && props.reportsData.length > 0 ? (
                        props.reportsData.map(report => (
                            report.report_type !== reportTypes.CUSTOM && (
                                <div key={report.report_id}>
                                    <TooltipWrapper content={report.report_name}>
                                        <span className="sideIcon" onClick={() => loadInsightReport(report.report_type)}>
                                            <ReportIcon type={report.report_type} activeReportType={activeReportType} />
                                        </span>
                                    </TooltipWrapper>
                                </div>
                            )
                        ))
                    ) : null
                ) : null}
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        reportsData: state.reports.reportsData,
        activeInsightReport: state.activeReport.activeInsightReport,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
       
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
