import React, { useContext, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import  { BsBellFill, BsFillInfoCircleFill, BsGrid3X3GapFill,BsFillPeopleFill, BsArrowRightCircleFill, BsFiletypePng, BsFiletypePpt }  from 'react-icons/bs';
import  { BsArrowClockwise, BsFiletypeDoc}  from 'react-icons/bs';
import Dropdown from "react-bootstrap/Dropdown";
import { getLoginToken } from "../../utilities/Utils";
import { STORAGE_KEYS } from "../../constant/SessionConstants";
import { MapContext } from '../../routes/MapContext';
import { AuthContext } from "../../routes/AuthContext";
import Logo from './Logo.jsx'
import { mapStyle, featureLabels, customReportType, reportAccessRoles, AUTH_TYPES, appName } from '../../constant/Constants';
import { fetchMapThemeUrl,insightRepEditStatus, refreshCalls, updateActionStatus } from '../../redux/actions/headerActions';
import { getApiKey, getUserReportAccessRole } from '../../utilities/Utils';
import { isFeatureAllowed } from '../../utilities/CommonUtils';
import { configAuthType } from "../../utilities/Utils";
import icCustomLayersOn from '../../assets/icon_custom_on.png';
import icCustomLayersOff from '../../assets/icon_custom_off.png';
import icTrafficOff from '../../assets/ml_traffic_off.png';
import icTrafficOn from '../../assets/ml_traffic_on.png';
import icWeatherOff from '../../assets/ml_weather_off.png';
import icWeatherOn from '../../assets/ml_weather_on.png';
import icMapOff from '../../assets/icon_map_off.png';
import icMapOn from '../../assets/icon_map_on.png';
import salogo from "../../assets/logo.png";
import srLogo from "../../assets/sr_logo.png";
import "../../styles/header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { MdOutlinePrint, MdEdit, MdOutlinePictureAsPdf } from "react-icons/md";
import * as powerbi from 'powerbi-client';
import axios from 'axios';
import { BiExport } from "react-icons/bi";
import {getPBIAccessToken, getExportId, getExportStatus} from '../../services/InsightService'
import { toast, Slide } from "react-toastify";
import { setFetchReportsDataStatus } from '../../redux/actions/reportsActions';
import { FaRegUserCircle, FaRegFileAlt } from "react-icons/fa";
import { FiLogOut, FiUsers } from "react-icons/fi";
import { ROUTE_ENDPOINTS } from "../../constant/RouteConstants";
import  NetworkContext  from 'sa-common/context/NetworkContext';

let refreshInProgress = false;

function Header(props) {
  const { t } = useTranslation()
  const location = useLocation();
  const navigate = useNavigate();
  const activepath = location.pathname;
  const { mapContainer, mapRef } = useContext(MapContext);
  const { authClient } = useContext(AuthContext);
  const isLoggedIn = getLoginToken();
  const userRepAccessRole = getUserReportAccessRole();
  const toastId = React.useRef(null);
  const [showMapLayers, toggleMapLayer] = useState();
  const [showTraffic, toggleTrafficLayer] = useState(false);
  const [showWeather, toggleWeatherLayer] = useState(false);
  const [showMapTheme, toggleMapTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [initialMap, setInitialMap] = useState(true);
  const profileName = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.GROUP_PROFILE));
  const [IsEditReportOn, setIsEditReportOn] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const dropdownRef = useRef(null);
  const [showExportOptions, toggleExportOptions] = useState();
  const [isDownloading, setDownloading] = useState(false);
  const { isOnline } = useContext(NetworkContext);
  const [userApps, setUserApps] = useState([]);

  useEffect(() => {
    const userAppsFrmSession = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.USER_APPLICATIONS));
    if (userAppsFrmSession && userApps && userApps.length == 0) {
      setUserApps(userAppsFrmSession);
    }
  }, [])

  useEffect(() => {
    if (mapContainer && mapRef.current) {
      // Map is ready, you can use mapContainer and mapRef safely here
      console.log("::: Header Map is ready :::: ")
      // setIsMapReady(true);
    }
  }, [mapContainer, mapRef]);

  useEffect(() => {
    if (props.mapUrl && mapContainer) {
      mapContainer.updateMapStyle(`${props.mapUrl}?api_key=${getApiKey()}`)
    }
  }, [props.mapUrl, mapContainer])

  useEffect(() => {
    if (props.fetchReportsDataStatus === false && refreshInProgress === true) {
      refreshInProgress = false;
      if (props.embeddedReportObj) {
        console.log("Refreshed");
        props.embeddedReportObj.refresh()
      }
      toast.update(toastId.current, { render: t("successMessages.reportsUpdateSuccess"), type: toast.TYPE.SUCCESS, autoClose: 3000 });
    } 
  }, [props.fetchReportsDataStatus])
 
  const handleSignOut = async () => {
    if (configAuthType() === AUTH_TYPES.OKTA) {
      await authClient.signOut();
      console.log('--- signout ---');
      sessionStorage.clear();
    }
  }

  const handleMapLayers = () => {
    if(isOnline) {
      toggleMapLayer(!showMapLayers)
    }
  }
  const handleTraffic = () => {
    if(isOnline) {
      mapContainer.showTrafficLayers(!showTraffic)
      toggleTrafficLayer(!showTraffic)
      props.updateActionStatus({statusType: 'trafficStatus', status: !showTraffic});
    }
  }
  const handleWeather = () => {
    if(isOnline) {
      mapContainer.showWeatherLayers(!showWeather)
      toggleWeatherLayer(!showWeather)
      props.updateActionStatus({statusType: 'weatherStatus', status: !showWeather});
    }
  }

  const handleMapTheme = () => {
    if(isOnline) {
      toggleMapTheme(!showMapTheme)
    }
  }
  
  const handleStyleSelection = (theme) => {
    if(isOnline) {
      setSelectedTheme(theme.Name);
      setInitialMap(false);
      props.fetchMapThemeUrl(theme.Url);
    }
  }

  const navigateToHomePage = () => {
    navigate(ROUTE_ENDPOINTS.INSIGHTS);
  }

  const editReportBtnClass = () => {
    if ((props.activeInsightReport && (props.activeInsightReport.report_type !== customReportType)) || (userRepAccessRole !== reportAccessRoles.CONTRIBUTOR)) {
      return 'disabled headerIcons';
    };
    return props.isEditReportOn ? 'active headerIcons' : 'inactive headerIcons';
  };

  useEffect(() => {
    handleEditInsightReport();
  }, [IsEditReportOn]);

  const handleEditInsightReport = () => {
    if (isOnline) {
      setIsEditReportOn(!props.isEditReportOn);
      props.insightRepEditStatus(IsEditReportOn);
    }
  }

  const handlePrintReport = () => {
    if(isOnline) {
      props.embeddedReportObj.print();
    }
  }

  const handleIconClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (url,active) => {
    // if(active === false){
    // window.open(url, '_blank');
    // }
    setDropdownOpen(false);
  }

  const handleExportOptions = () => {
    toggleExportOptions(!showExportOptions)
  }

  const exportAsPng = () => {
    exportAs("PNG")
  }

  const exportAsPdf = () => {
    exportAs("PDF")
  }

  const exportAsPpt = () => {
    exportAs("PPTX")
  }

  const exportAs = async (format) => {
    if (isOnline) {
      if (!isDownloading) {
        try {
          console.log("props.embeddedReportObj ::: ", props.embeddedReportObj);
          console.log("props.activeInsightReport ::: ", props.activeInsightReport);
          const reportId = props.embeddedReportObj.config.id;
          const groupId = props.activeInsightReport.workspaceid;
          setDownloading(true)
          toast(`${t("header.exporting")} ${format}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });

          // get access token from server 
          const accessTokenResponse = await getPBIAccessToken();
          if (accessTokenResponse.status !== 200) {
            throw new Error('Failed to get access token');
          }
          const accessToken = accessTokenResponse.data.token;

          const exportIdResponse = await getExportId(accessToken, reportId, groupId, format, props.activeInsightReport);
          if (exportIdResponse.status !== 200 && exportIdResponse.status !== 202) {
            throw new Error('Failed to get export ID');
          }
          const exportId = exportIdResponse.data.id;
          let statusResponse;
          let exportStatus = 'Running';

          while (exportStatus === 'Running') {
            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds
            statusResponse = await getExportStatus(accessToken, reportId, groupId, exportId);
            if (statusResponse.status >= 300) {
              throw new Error('Failed to get export status');
            }
            exportStatus = statusResponse.data.status;
          }

          // Start the Download
          if (exportStatus === 'Succeeded') {
            const downloadUrl = statusResponse.data.resourceLocation;
            const response = await axios.get(downloadUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${accessToken}` } });
            if (response.status !== 200) {
              throw new Error('Failed to download file');
            }

            // Determine file extension based on expected file type
            let extension;
            const contentType = response.headers['content-type'];
            if (contentType.includes('pdf')) {
              extension = 'pdf';
            } else if (contentType.includes('zip')) {
              extension = 'zip';
            } else if (contentType.includes('vnd.openxmlformats-officedocument.presentationml.presentation')) {
              extension = 'pptx'; // For PowerPoint
            } else if (contentType.includes('png')) {
              extension = 'png';
            } else {
              // Handle other file types or set a default extension
              extension = 'file'; // or any other default extension
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const fileName = props.activeInsightReport.activePageData.DisplayName
            link.href = url;
            link.setAttribute('download', `${fileName}.${extension}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            toast.success(`${t("header.exportSuccess")}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Slide,
            });
          } else {
            setDownloading(false)
            // Handle export failure
            console.error('Export failed:', statusResponse.data.error);
            toast.error(`${t("header.exportFail")}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Slide,
            });
          }
        } catch (error) {
          // Handle other errors
          console.error('Error exporting the file:', error);
          toast.error(`${t("header.exportFail")}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
          });
          setDownloading(false)
        } finally {
          setDownloading(false)
        }
      } else {
        toast.warning(`${t("header.exportInProfress")}`, { toastId: toastId });
      }
    }
  };

  const handleRefresh = () => {
    if (isOnline) {
      refreshInProgress = true;
      toast.dismiss()
      toastId.current = toast(t("successMessages.updatingReports"), { type: toast.TYPE.SUCCESS, autoClose: false });
      props.setFetchReportsDataStatus(true)
    }
  }

  const handleCallsRefresh = () => {
    if (isOnline) {
      props.refreshCalls(true);
      if(props.refreshApi){
        setTimeout(() => {
          props.refreshCalls(false)
        }, 1000);
      }
    }
  }

  const navigateToProfile = () => navigate(ROUTE_ENDPOINTS.PROFILE);
  const navigateToAbout = () =>  navigate(ROUTE_ENDPOINTS.ABOUT);


  return (
    <>
      <div className="header">
        <div className="psap-header header-bar">
          <div className="d-flex align-items-center">
          <div className="menu-grid">
              <div className='gird-fill' ref={dropdownRef}>
                <Dropdown show={isDropdownOpen} onToggle={(isOpen) => setDropdownOpen(isOpen)}>
                  <Dropdown.Toggle drop='left' as="div" className="profile-img" onClick={handleIconClick}>
                    <BsGrid3X3GapFill />
                  </Dropdown.Toggle>
                  {userApps && userApps.length && isDropdownOpen ? (
                    <Dropdown.Menu className="grid-dropdown">
                      {userApps.map((item, index) => (
                        <Dropdown.Item key={index} href={!item.isCurrentApp ? item.url : '#'} target='_blank' onClick={() => handleItemClick(item.url, item.active)} className="dropdown-item-with-border"> 
                          <div className="flexRow">
                            <div className="text-icon">
                              {item.isCurrentApp === true ? <BsArrowRightCircleFill />:''}
                            </div>
                            <div className="selection-text">
                              {item.label}
                            </div>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  ) : ''}
                </Dropdown>
              </div>
              <div className='hand-cursor' onClick={navigateToHomePage}>
                <img src={salogo} className='sa-logo' />
                {/* <img src={srLogo} /> */}
                <span className="logo-text">{appName}</span>
              </div>
            </div>
            <Logo />
            <div>
              <span><BsFillPeopleFill className='people-icon'/></span>
              <span className='groupName'>{profileName.name}</span>
            </div>
          </div>
          {isLoggedIn ? (
            <div className="float-right user-name d-flex align-items-center">
              {activepath == '/insights' ? 
                  (<>
                  <div className="hand-cursor nav-list" onClick={handleRefresh}>
                    <BsArrowClockwise size={14} />
                    <span className='headerIcons'>{t("header.refresh")}</span>
                  </div>
                  <div className="hand-cursor nav-list" onClick={handleEditInsightReport}>
                    <MdEdit size={14} className={editReportBtnClass()}/>
                      <span className={editReportBtnClass()}>{t("header.edit_report")}</span>
                  </div>
                  <div className="hand-cursor nav-list" onClick={handlePrintReport}>
                    <MdOutlinePrint size={14}/>
                    <span className='headerIcons'>{t("header.print")}</span>
                  </div>
                  <div className='hand-cursor nav-list map-style map-layers' onClick={handleExportOptions} onBlur={handleExportOptions}>
                    <Dropdown className='hand-cursor d-inline mx-2'>
                      <Dropdown.Toggle drop='left'>
                        {showExportOptions ? <BiExport  size={14}/> : <BiExport  size={14}/>}
                        <span className='headerIcons'>{t("header.export")}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu >
                          <Dropdown.Item>
                            <div className={`hand-cursor nav-list`} onClick={exportAsPdf}>
                              <MdOutlinePictureAsPdf size={14}/>
                              <span className='headerIcons'>{t("header.pdf")}</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className={`hand-cursor nav-list`} onClick={exportAsPng}>
                              <BsFiletypePng size={14}/>
                              <span className='headerIcons'>{t("header.png")}</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className={`hand-cursor nav-list`} onClick={exportAsPpt}>
                              <BsFiletypePpt size={14}/>
                              <span className='headerIcons'>{t("header.ppt")}</span>
                            </div>
                          </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {isDownloading ? <div className="circular-progress"></div> : ''}
                  </>
                  )
               : ''}
              {activepath == ROUTE_ENDPOINTS.MAP ? (
              <>
              <div className="hand-cursor nav-list" onClick={handleCallsRefresh}>
                  <BsArrowClockwise size={14} />
                  <span className='headerIcons'>{t("header.refresh")}</span>
                </div>
              <div className='hand-cursor nav-list map-style' onClick={handleMapTheme}>
                <Dropdown className='hand-cursor '>
                  <Dropdown.Toggle drop='left'>
                    <img src={showMapTheme ? icMapOn : icMapOff} className='nav-icon' />
                    {t("header.map_style")}
                  </Dropdown.Toggle>
                  {props.mapThemes && props.isLoading == undefined && props.mapThemes.length ?
                    <Dropdown.Menu >
                      {props.mapThemes.map((list, ind) => {
                        return (
                          <Dropdown.Item key={ind} className={(selectedTheme === list.Name || initialMap && ind === 1) ? 'selected' : null} onClick={() => handleStyleSelection(list)}>
                            <img src={mapStyle.images[list.Name] ? mapStyle.images[list.Name] :
                              list.Name.toLowerCase().includes(mapStyle.themes.dark) ? mapStyle.images[mapStyle.themes.dark] :
                                list.Name.toLowerCase().includes(mapStyle.themes.day) ? mapStyle.images[mapStyle.themes.day] :
                                  list.Name.toLowerCase().includes(mapStyle.themes.light) ? mapStyle.images[mapStyle.themes.light] :
                                    list.Name.toLowerCase().includes(mapStyle.themes.night) ? mapStyle.images[mapStyle.themes.night] :
                                      mapStyle.images['default']} className='mapstyle-icon' />
                            {list.Name}
                          </Dropdown.Item>)
                      })}

                    </Dropdown.Menu>
                    : ''}
                </Dropdown>
              </div>

              <div className='hand-cursor nav-list map-style map-layers' onClick={handleMapLayers} onBlur={handleMapLayers}>
                <Dropdown className='hand-cursor d-inline mx-2'>
                  <Dropdown.Toggle drop='left'>
                    <img src={showMapLayers ? icCustomLayersOn : icCustomLayersOff} className='nav-icon' />
                    {t("header.map_layers")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    {isFeatureAllowed(featureLabels.traffic) ?
                      <Dropdown.Item>
                        <div className={`hand-cursor nav-list ${showTraffic ? 'active' : ''}`} onClick={handleTraffic}>
                          <img src={showTraffic ? icTrafficOn : icTrafficOff} className='nav-icon' />
                          <span className={showTraffic ? "active" : "inactive"}>{t("header.traffic")}</span>
                        </div>
                      </Dropdown.Item>
                      : ''}
                    {isFeatureAllowed(featureLabels.weather) ?
                      <Dropdown.Item>
                        <div className={`hand-cursor nav-list layer-divider ${showWeather ? 'active' : ''}`} onClick={handleWeather}>
                          <img src={showWeather ? icWeatherOn : icWeatherOff} className='nav-icon' />
                          <span className={showWeather ? "active" : "inactive"}>{t("header.weather")}</span>
                        </div>
                      </Dropdown.Item>
                      : ''}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </>) : ''}
              <Dropdown className='hand-cursor nav-list header-options-list'>
                <Dropdown.Toggle drop='left' as="div" className="profile-img d-flex align-items-center">
                  {sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER)}
                  <span className='user-name-icon'>{sessionStorage.getItem(STORAGE_KEYS.CURRENT_USER).charAt(0)}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={navigateToProfile}>
                    <span className="option-icon"><FaRegUserCircle /></span> {t("header.profile")}
                  </Dropdown.Item>
                  <Dropdown.Divider />  
                  <Dropdown.Item onClick={navigateToAbout}>
                    <span className="option-icon"><FiUsers /></span> {t("header.about")}
                  </Dropdown.Item>
                  <Dropdown.Divider />  
                  <Dropdown.Item href={`${process.env.PUBLIC_URL}/assets/Comtech_SmartAnalytics_UserGuide.pdf`} target='_blank'>
                    <span className="option-icon"><FaRegFileAlt /></span> {t("header.documentation")}
                  </Dropdown.Item>
                  <Dropdown.Divider /> 
                  <Dropdown.Item onClick={handleSignOut}>
                    <span className="option-icon"><FiLogOut /></span> {t("header.sign_out")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : ''}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
    mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
    isEditReportOn: state.editRepStatus.isEditReportOn,
    activeInsightReport: state.activeReport.activeInsightReport,
    embeddedReportObj: state.embedReport.embeddedRepObj,
    fetchReportsDataStatus: state.reports.fetchReportsDataStatus,
    refreshApi: state.refreshApi
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMapThemeUrl: (url) => dispatch(fetchMapThemeUrl(url)),
    insightRepEditStatus: (val) => dispatch(insightRepEditStatus(val)),
    setFetchReportsDataStatus: (status) => dispatch(setFetchReportsDataStatus(status)),
    refreshCalls: (val) => dispatch(refreshCalls(val)),
    updateActionStatus: (status) => dispatch(updateActionStatus(status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
