import axios from 'axios';
import {  configTimers, baseApi, serverUrls, powerBiIdentities } from '../constant/Constants';
import { getOktaAccessToken } from '../utilities/Utils';
import { STORAGE_KEYS } from "../constant/SessionConstants";

export const getCallInfo = (start, end) => {
  const oktaAccessToken = getOktaAccessToken();
  const apiKey = sessionStorage.getItem(STORAGE_KEYS.API_KEY);
  return axios.get(
    `${baseApi.smanUrl}/sman/datasource/callinfo/${apiKey}?starttime=${start}&endtime=${end}`,
    {
      headers: {
          'Authorization': 'Bearer '+ oktaAccessToken,
          'Content-Type': 'application/json'
      }
    }
  )
}