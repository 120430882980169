export const urls = {
	debuggLogs: true,
	statusCheckUrl: 'https://apis.qa.smartresponse.net',
};

export const oktaUrls = {
	APP_NAME: 'ComtechAnalytics',
	OKTA_BASE_URL: 'https://comtechng911.oktapreview.com',
	CLIENT_ID: '0oae153irfPpwewgQ1d7',
	ISSUER: '/oauth2/ause2837l6MOTLkV11d7',
};

export const primaryRegionData = {
	baseUrl:'https://apis.qa.smartresponse.net',
	smanBaseUrl: 'https://apis-sman.qa.smartresponse.net',
	createKongKeyAndUserAPI: 'https://apis-sman.qa.smartresponse.net/oauth/CreateKongKeyAndUserInSmanDb',
	aws_project_region: "us-west-2",
};

export const secondaryRegionData  = {
	baseUrl:'https://apis.qa-ohio.smartresponse.net',
	smanBaseUrl: 'https://apis-sman.qa.smartresponse.net',
	createKongKeyAndUserAPI: '',
	aws_project_region: "us-east-1",
};