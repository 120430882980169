import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import Header from "../presentation/views/Header";
import Footer from "../presentation/views/Footer";
import OktaLoginPage from "../presentation/views/OktaLoginPage";
import AppCommon from "../presentation/views/AppCommon";
import { ROUTE_ENDPOINTS } from "../constant/RouteConstants";
import Dashboard from '../presentation/views/Dashboard';
import Profile from '../presentation/settings/Profile';
import Settings from '../presentation/settings/Settings';
import { MapProvider } from './MapContext';
import { AuthProvider } from "./AuthContext";
import { configAuthType } from '../utilities/Utils';
import { AUTH_TYPES } from '../constant/Constants';

// const InsightForm = lazy(() => import('../presentation/insights/InsightForm'));
// const CallInfoComponent = lazy(() => import('../presentation/map/CallInfoComponent'));
import InsightForm from '../presentation/insights/InsightForm';
import CallInfoComponent from '../presentation/calls/CallInfoComponent';
import About from '../presentation/settings/About';


export default function AppRouter() {
	const authType = configAuthType();
	const queryParameters = new URLSearchParams(window.location.search);
  const issParam = queryParameters.get("iss"); // iss: Okta domain url

	const routes = () => {
		return (
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route path={ROUTE_ENDPOINTS.LOGIN} element={authType === AUTH_TYPES.OKTA ? (<OktaLoginPage issParam={issParam} />) : (<OktaLoginPage issParam={issParam} />)} />
					{authType === AUTH_TYPES.OKTA ? (<Route path="/login-callback" element={<OktaLoginPage isLoginCallback={true} />} />) : ''}
					<Route element={<PrivateRoute />}>
						<Route element={<AppCommon />}>
						<Route element={
							<MapProvider>
								<Header />
								<Dashboard />
							</MapProvider>
						}>
							<Route path={ROUTE_ENDPOINTS.INSIGHTS} element={<InsightForm />} />							
							<Route path={ROUTE_ENDPOINTS.MAP} element={<CallInfoComponent />} />
							<Route path={ROUTE_ENDPOINTS.PROFILE} element={<Profile />} />
							<Route path={ROUTE_ENDPOINTS.ABOUT} element={<About />} />	
							<Route path={ROUTE_ENDPOINTS.SETTINGS} element={<Settings />} />					
						</Route>
						</Route>
					</Route>
					<Route path="*" element={<Navigate to={ROUTE_ENDPOINTS.LOGIN} />} />
				</Routes>
			</Suspense>
		)
	}

	return (
		<BrowserRouter>
			<AuthProvider>
				{ routes() }
				<Footer />
			</AuthProvider>
		</BrowserRouter>
	);
}